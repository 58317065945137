import { ErrorLayout } from '@client/components/Scaffolding/ErrorLayout';
import { HTTPStatusCode } from '@sbt-web/network/types';
import { ButtonLink } from '@sbt-web/ui';
import dynamic from 'next/dynamic';
import NextError from 'next/error';
import Head from 'next/head';
import React, { useEffect } from 'react';

import classes from './styles.module.scss';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { SkeletonWithAdv } from '@client/components/Adv/SkeletonWithAdv';
import { AdvScripts, LiraAdvContainer, LiraRunAdvScript } from '@sbt-web/adv';
import Script from 'next/script';
import { createSkyscraperSizeConstraints } from '@client/components/Adv/Lira/createSkyscraperSizeConstraints';
import { errorNotFoundConfig } from '@client/components/Adv/Lira/fallbackConfig/desktopErrorConfig';
import { msiteErrorNotFoundConfig } from '@client/components/Adv/Lira/fallbackConfig/msiteErrorConfig';

// @ts-expect-error try
declare let pbjs;

const ComputerFlu = dynamic(
  () => import('./assets').then((a) => a.ComputerFlu),
  { ssr: true }
);
const TRexAsset = dynamic(() => import('./assets').then((a) => a.TRexAsset), {
  ssr: true,
});

interface Props {
  statusCode: HTTPStatusCode;
}

const ErrorPage: React.FunctionComponent<Props> = ({ statusCode }) => {
  let title: string, message: React.ReactElement, image: React.ReactElement;

  useEffect(() => {
    console.log('PrebidJS example!');

    const sizes = [[300, 250]];
    // use the cool "Prebid Less" feature https://docs.prebid.org/dev-docs/adunit-reference.html#stored-imp
    const adUnits = [
      {
        code: 'errornotfound-belowthefold',
        mediaTypes: {
          banner: {
            sizes,
          },
        },
        bids: [
          {
            module: 'pbsBidAdapter',
            ortb2Imp: {
              ext: {
                prebid: {
                  storedrequest: {
                    id: 'errornotfound-belowthefold',
                  },
                },
              },
            },
          },
        ],
      },
    ];

    function renderAllAdUnits() {
      const winners = pbjs.getHighestCpmBids();
      for (let i = 0; i < winners.length; i++) {
        renderOne(winners[i]);
      }
    }
    // create an iframe in the div and write the winning ad into it
    // @ts-expect-error try
    function renderOne(winningBid) {
      if (winningBid && winningBid.adId) {
        const div = document.getElementById(winningBid.adUnitCode);
        if (div) {
          const iframe = document.createElement('iframe');
          iframe.scrolling = 'no';
          iframe.frameBorder = '0';
          iframe.marginHeight = '0';
          iframe.marginHeight = '0';
          iframe.name = `prebid_ads_iframe_${winningBid.adUnitCode}`;
          iframe.title = '3rd party ad content';
          iframe.sandbox.add(
            'allow-forms',
            'allow-popups',
            'allow-popups-to-escape-sandbox',
            'allow-same-origin',
            'allow-scripts',
            'allow-top-navigation-by-user-activation'
          );
          iframe.setAttribute('aria-label', 'Advertisment');
          iframe.style.setProperty('border', '0');
          iframe.style.setProperty('margin', '0');
          iframe.style.setProperty('overflow', 'hidden');
          div.appendChild(iframe);
          // @ts-expect-error try
          const iframeDoc = iframe.contentWindow.document;
          pbjs.renderAd(iframeDoc, winningBid.adId);
        }
      }
    }

    pbjs = pbjs || {};
    pbjs.que = pbjs.que || [];
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(() => {
      pbjs.que.push(() => {
        pbjs.setConfig({
          s2sConfig: [
            {
              accountId: '1',
              adapter: 'prebidServer',
              enabled: true,
              endpoint: {
                noP1Consent:
                  'https://hades.piutardi.it/prebid/openrtb2/auction',
                p1Consent: 'https://hades.piutardi.it/prebid/openrtb2/auction',
              },
              timeout: 1000,
              debug: true,
              allowUnknownBidderCodes: true,
            },
          ],
          consentManagement: {
            gdpr: {
              cmpApi: 'iab',
              timeout: 500,
              actionTimeout: 10000,
              defaultGdprScope: true,
            },
          },
        });

        pbjs.addAdUnits(adUnits);
        pbjs.requestBids({
          bidsBackHandler: renderAllAdUnits,
        });
      });
    });
  }, []);

  switch (statusCode) {
    case HTTPStatusCode.BadRequest:
    case HTTPStatusCode.NotFound: {
      title = "Su Subito trovi tutto. Questa pagina, però, non c'è...";
      message = (
        <p>
          Ci dispiace: non siamo riusciti a trovare la pagina che hai richiesto.
          <br />È solo una pagina, per fortuna, non l'affare dei tuoi sogni.
        </p>
      );
      image = <TRexAsset />;
      break;
    }
    case HTTPStatusCode.InternalServerError:
    case HTTPStatusCode.BadGateway:
    case HTTPStatusCode.ServiceUnavailable:
    case HTTPStatusCode.GatewayTimeout: {
      title = 'Si è verificato un errore.';
      message = (
        <p>
          Ti preghiamo di riprovare tra qualche istante. <br /> Se il problema
          dovesse persistere, ti preghiamo di
          <a href="https://assistenza.subito.it/hc/it/">
            <strong> contattare il servizio clienti</strong>
          </a>
        </p>
      );
      image = <ComputerFlu />;

      break;
    }
    default:
      return <NextError statusCode={statusCode} />;
  }

  return (
    <SkeletonWithAdv
      skyscraperRight={{
        id: LiraContainerIds.pageError.skyscraperRight,
        enabled: true,
      }}
      skyscraperLeft={{
        id: LiraContainerIds.pageError.skyscraperLeft,
        enabled: true,
      }}
    >
      <Head>
        <title>Subito.it</title>
      </Head>

      {statusCode === HTTPStatusCode.NotFound ? (
        <>
          <AdvScripts
            Script={Script}
            config={{
              includeOw: false,
              includeTam: false,
              includeAdSense: true,
              includePrebid: true,
              publicPath: '/static/script',
            }}
          />
          <LiraRunAdvScript
            NextJsScriptComponent={Script}
            vertical="subito"
            pageType="errornotfound"
            env={process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT}
            fallbackHebeConfig={{
              desktop: errorNotFoundConfig('subito'),
              msite: msiteErrorNotFoundConfig('subito'),
            }}
            constraintsByContainerId={createSkyscraperSizeConstraints(
              LiraContainerIds.pageError.skyscraperLeft,
              LiraContainerIds.pageError.skyscraperRight
            )}
          />
        </>
      ) : null}

      <ErrorLayout title={title} message={message} image={image} />
      <div className={classes['btn-container']}>
        <ButtonLink design="outline" href="/">
          Torna in homepage
        </ButtonLink>
      </div>
      <div>
        <LiraAdvContainer
          idForAd={LiraContainerIds.pageError.belowTheFold}
          className={classes['slot-adv']}
        />
      </div>
    </SkeletonWithAdv>
  );
};

export default ErrorPage;
